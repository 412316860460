.eye {
  position: relative;
  height: 100%;
  width: 100%;

  img {
    height: 100%;
    width: 100%;
  }
}
