.faces {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;

  p {
    color: rgb(2, 2, 1);
    width: 80vw;
    margin-left: 32px;
    font-size: 28px;
  }
}

@media only screen and (min-width: 768px) {
  .faces {
    flex-wrap: wrap;
    flex-direction: row;

    p {
      width: 45vw;
      margin-top: 32px;
      font-size: 24px;
    }
  }
}

@media only screen and (min-width: 1024px) {
  .faces {
    p {
      width: 20vw;
    }
  }
}
