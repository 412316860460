.shea {
  height: 50vh;
  width: 100%;
  border: 2px solid black;
  background-image: url("../../Assets/Shea.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  position: relative;

  .flexy {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: space-between;
    height: 6%;
    width: 26%;
    position: absolute;
    top: 37%;
    left: 50%;
    transform: rotate(2deg);

    .lEye {
      margin-right: 16px;
    }

    .rEye {
      margin-left: 2px;
      margin-top: 4px;
    }
  }
}

@media only screen and (min-width: 375px) {
  .shea {
    height: 70vh;
    .flexy {
      width: 25%;
      top: 31%;
      margin-left: 2px;

      .lEye {
        margin-right: 15px;
      }

      .rEye {
        margin-left: 6px;
        margin-top: 8px;
      }
    }
  }
}

@media only screen and (min-width: 425px) {
  .shea {
    .flexy {
      top: 33%;
    }
  }
}

@media only screen and (min-width: 768px) {
  .shea {
    height: 50vh;
    width: 40vw;

    .flexy {
      top: 37%;
      left: 50%;

      .lEye {
        margin-right: 10px;
      }

      .rEye {
        margin-left: 12px;
        margin-top: 3px;
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .shea {
    height: 100%;
    width: 100%;

    .flexy {
      width: 30%;
      top: 29%;
      left: 49%;
     display: flex;
     justify-content: space-between;

      .lEye {
        margin-right: 10px;
      }

      .rEye {
        margin-left: 6px;
      }
    }
  }
}

@media only screen and (min-width: 1100px) {
  .shea {
    height: 50vh;
    width: 20vw;

    .flexy {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: space-between;
      height: 6%;
      width: 25%;
      position: absolute;
      top: 32%;
      left: 50%;
      transform: rotate(2deg);

      .lEye {
        margin-right: 10px;
      }

      .rEye {
        margin-left: 12px;
      }
    }
  }
}
